import createClients from "./create-clients"

const config = {
	middlewares: false,
	fake_api: false
}
const store = {
	dispatch: (toDispatch) => {console.log(toDispatch)}
}
const params = {}
const offline = true

let ApiClient

const getApiClient = () => {
	if( ! ApiClient )
		ApiClient = createClients(config, store).ApiClient

	return ApiClient
}

export default {
	getAll: () => getApiClient().get(`/testimonials`, {params, offline}),

	getMe: () => getApiClient().get(`/testimonials/me`, {params, offline}),

	get: token => getApiClient().get(`/testimonials/${ token }`, {params, offline}),

	update : token => getApiClient().put(`/testimonials/${ token }`, {params, offline}),

	create: params => getApiClient().post(`/testimonials`, {params, offline})
}
