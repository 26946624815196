import classNames from 'classnames'
import { Component, useState } from 'react'

import { Icon } from 'components/media'

export default class Slideshow extends Component {
	constructor( props ) {
    super( props )

		this.last = this.props.children.length

		this.state = {
			selected: 1
		}
	}

	componentDidMount() {
		this.setInterval()
	}

	componentWillUnmount() {
		this.clearInterval()
	}

	setInterval() {
		this.timer = setInterval( () => {
			this.next()
		}, this.props.delay || 4000 )
	}

	clearInterval() {
		clearInterval( this.timer )
	}

	resetInterval() {
		this.clearInterval()
		this.setInterval()
	}

	// TODO: add touch event support

	handleClickPrev() {
		this.resetInterval()
		this.prev()
	}

	handleClickNext() {
		this.resetInterval()
		this.next()
	}

	prev() {
		this.setState( {
			selected: this.state.selected > 1 ? this.state.selected - 1 : this.last
		} )
	}

	next() {
		this.setState( {
			selected: this.state.selected < this.last ? this.state.selected + 1 : 1
		} )
	}

	render() {

		return (
			<section className="c-slideshow">
				<div
					className={ classNames(
						'c-slideshow__container',
						[`c-slideshow--selected-${this.state.selected}`]
					) }
					style={ { '--selected': this.state.selected } }
				>

					{ this.props.children }

				</div>
				<div className="c-slideshow__arrow">
					<div className="c-slideshow__arrow-prev">
						<Icon
							onClick={ () => this.handleClickPrev() }
							name="chevron-left"
							size="large"
							color="light-gray"
						/>
					</div>
					<div className="c-slideshow__arrow-next">
						<Icon
							onClick={ () => this.handleClickNext() }
							name="chevron-right"
							size="large"
							color="light-gray"
						/>
					</div>
				</div>
			</section>
		)
	}
}
