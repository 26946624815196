import classNames from 'classnames'
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import Title from 'components/title'
import Button from 'components/button'
import Section from 'components/section'
import { Navbar, Menu } from 'components/navigation'
import { Figure, Icon } from 'components/media'
import { Meta } from 'components/generic'

import { scrollToNext } from 'libs/navigation'
import { DEFAULT_HERO_IMAGE, CSS_BREAKPOINT_SEPARATOR } from 'libs/variables'

// inuitcss vars
const heroBpName = 'tablet'

export default function Hero( props ) {

	const {
		id,
		title,
		description,
		image,
		overlay,
		aside,
		back,
		more,
		className
	} = props

	// Navigation
	const containerRef = useRef()
	const handleClick = () => scrollToNext( containerRef.current )

	return (
		<Section
			id={ id }
			className={ classNames(
				'c-hero o-section--parallax',
				{
					'c-hero--overlay': overlay && overlay !== 'none'
				},
				className
			) }
		>

			<div
				className="c-hero__container o-wrapper u-color--white"
				ref={ containerRef }
			>

				<Figure
					className="c-hero__figure"
					isRounded
					hasOverlay
					overlay={ overlay }
					image={ image ?? DEFAULT_HERO_IMAGE }
				/>

				<div
					className="c-hero__wrapper o-wrapper u-position-sticky u-top u-height--full u-width--full-absolute u-flex-align--center"
					onClick={ handleClick }
				>
					<div className="o-layout o-layout--flex o-layout--middle u-flex-grow">

						<div
							className={ classNames(
								'c-hero__content',
								'o-layout__item',
								{
									[`u-1/2${CSS_BREAKPOINT_SEPARATOR}${heroBpName}`]: aside ,
									[`u-2/3${CSS_BREAKPOINT_SEPARATOR}${heroBpName}`]: ! aside
								}
							) }
						>
							<div>
								{ back && (
									<div
										className="o-subtitle o-subtitle--normal u-margin-bottom-normal"
										onClick={ e => e.stopPropagation() }
									>
										{ back }
									</div>
								) }
								<Title tag="h1" className="u-margin-bottom-none">{ title }</Title>
								{ description && (
									<div className="u-font-size--medium u-margin-top-large">{ description }</div>
								) }
							</div>
						</div>

						{ aside && (
							<div
								className={ classNames(
									'c-hero__aside',
									'o-layout__item u-flex-align--center u-flex-justify--end',
									`u-1/2${CSS_BREAKPOINT_SEPARATOR}${heroBpName}`
								) }
							>
								{ aside }
							</div>
						) }

					</div>
				</div>

			</div>

			<Navbar
				className={ classNames(
					'c-hero__more',
					'u-position-sticky',
				) }
				zIndex={ 1 }
			>
				<Menu
					align="center"
					valign="center"
					hasLayout
				>
					<Icon
						className="c-hero__more--icon"
						name="chevron-down"
						size="large"
						color="light-gray"
						onClick={ handleClick }
					/>
					{ more && (
						<Button
							className="c-hero__more--button"
							size="small"
							isPrimary
							onClick={ handleClick }
						>
							{ more }
						</Button>
					) }
				</Menu>
			</Navbar>
		</Section>
	)
}

Hero.propTypes = {
	title: PropTypes.node.isRequired,
	image: PropTypes.string,
	overlay: PropTypes.string,
	aside: PropTypes.node,
	back: PropTypes.node,
	dscription: PropTypes.node,
	more: PropTypes.string,
	className: PropTypes.string
}

Hero.defaultProps = {
	overlay: 'primary'
}
