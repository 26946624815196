import Link from "next/link"

import Card, { CardContent, CardFooter } from "components/card"
import Layout, { LayoutItem } from "components/layout"
import { ContentMarkdown } from "components/markdown"
import { Figure } from "components/media"
import Title, { Subtitle } from "components/title"
import { Legend } from "components/generic"
import Button from "components/button"

export default function TestimonialCard( props ) {

	const {
		isNew,
		displayName,
		taxon,
		message,
		status,
		image: { cachedPath: image }
	} = props

	if ( isNew ) {
		return (
			<Card
				layout="1/2"
			>
				<CardContent size="medium">
					<Title size="medium" className="u-margin-bottom-medium">Racontez-nous votre escapade</Title>
				</CardContent>
				<CardFooter size="medium">
					<Button
						href="/temoignage#temoigner"
					>
						Ajouter un témoignage
					</Button>
				</CardFooter>
			</Card>
		)
	}

	return (
		<Card
			layout="1/2"
		>
			<CardContent size="medium">
				<ContentMarkdown isSummary>{ message }</ContentMarkdown>
				{ status !== 'published' && (
					<Legend className="u-color--error u-margin-top-normal">En cours d'approbation</Legend>
				) }
			</CardContent>
			<CardFooter size="medium">
				<Layout isFlex isRow isAuto size="normal" vAlign="middle">
					<LayoutItem>
						<Figure
							isRounded
							size="huge"
							ratio="1:1"
							image={ image || taxon.image }
						/>
					</LayoutItem>
					<LayoutItem isPrimary>
						<Title size="medium">{ displayName }</Title>
						<Subtitle size="normal">
							<Link
								href={ taxon.href }
								as={ taxon.as }
							>
								<a>
									{ taxon.name }
								</a>
							</Link>
						</Subtitle>
					</LayoutItem>
				</Layout>
			</CardFooter>
		</Card>
	)
}

TestimonialCard.defaultProps = {
	image: {
		cachedPath: undefined
	}
}
