import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { NextSeo } from "next-seo";

import { scrollToNext } from "libs/navigation";
import { arrayShuffle, isArray, isEmpty, isNull } from "libs/utils";
import { formatCategory, formatNews } from "libs/formatters";

import testimonialClient from "clients/testimonial-client";
import newsClient from "clients/news-client";

import Slideshow from "components/slideshow";
import Button from "components/button";
import Card, { CardHeader, CardContent, CardFooter } from "components/card";
import Title from "components/title";
import Markdown from "components/markdown";
import Hero from "components/hero";
import { Icon } from "components/media";
import Section, { SectionLayout, SectionContent } from "components/section";
import Wrapper from "components/wrapper";
import Layout, { LayoutItem, Grid } from "components/layout";
import SidebarNav from "components/sidebar-nav";
import { Navbar, Menu } from "components/navigation";
import TestimonialCard from "components/testimonial/card";
import Carousel from "components/carousel";


Controller.getInitialProps = async ctx => {
	let initialProps = {
		testimonial: null,
		news: null,
		error: null
	}

	try {

		// Testimonials
		const testimonialsResponse = await testimonialClient.getAll()

		if ( isArray( testimonialsResponse ) ) {
			if ( ! isEmpty( testimonialsResponse ) ) {

				initialProps.testimonials = testimonialsResponse.map( testimonial => {
					testimonial.taxon = formatCategory( testimonial.taxon )
					return testimonial
				} )
			} else {
				initialProps.testimonials = []
			}
		}

		// News
		const newsResponse = await newsClient.getAll()
		
		if ( isArray( newsResponse ) ) {
			if ( ! isEmpty( newsResponse ) ) {

				initialProps.news = formatNews(newsResponse)
			} else {
				initialProps.news = []
			}
		}

	} catch (e) {
		initialProps.error = 500
	}

	return initialProps
}

export default function Controller( { testimonials, news } ) {
	return (
		<>
			<NextSeo
				title="Bienvenue"
				description="Séjours surprises en France. Séjours insolites pour découvrir les richesses de nos régions."
			/>
			<Slideshow>
				<Hero
					className="c-slideshow__slide"
					image="/images/sejours_surprises.jpg"
					overlay="none"
					title="Séjours surprises"
					more="Découvrir le concept"
				/>
				<Hero
					className="c-slideshow__slide"
					image="/images/creation_jeux.jpg"
					overlay="none"
					title="Création de jeux"
					more="Découvrir notre savoir-faire"
				/>
				<Hero
					className="c-slideshow__slide"
					image="/images/agence-evenementielle.jpg"
					overlay="none"
					title="Team-Building"
					more="Découvrir nos prestations"
				/>
			</Slideshow>

			<Section id="decouvrir" className="u-position-relative">
				<Wrapper>
					<div className="u-width--full-absolute">
						<Layout>
							<LayoutItem
								widths={{
									desktop: "1/3",
								}}
							>
								<Card
									image="/images/Sibylline_web-20.jpg"
									size="medium"
									hasOverlay
									overlay="shadow-from-b"
									isFullHeight
								>
									<CardHeader
										size="medium"
										className="u-min-height--one-third"
									/>
									<CardContent size="medium" />
									<CardContent
										size="medium"
										className="u-min-height--one-third"
									>
										<Title tag="h3" size="large">
											Séjours surprises
										</Title>
										<Markdown>
											Un séjour surprise à 2h30 maximum de votre domicile pour partir à la découverte des richesses de nos territoires français. Remplissez le questionnaire pour définir les dates de votre séjour et surtout pour qu'on apprenne à vous connaître. Le programme reste surprise jusqu'à la dernière minute et sera découvert sous forme d'indices, numérotés avec la date et l'heure d’ouverture.
										</Markdown>
									</CardContent>
									<CardFooter size="medium" className="c-card__meta">
										<Button 
											isPrimary 
											color="white" 
											href="/[taxon]"
											as="/sejours-surprises"
										>
											Découvrir le concept
										</Button>
									</CardFooter>
								</Card>
							</LayoutItem>

							<LayoutItem
								widths={{
									desktop: "1/3",
								}}
							>
								<Card
									image="/images/creation_jeux.jpg"
									hasOverlay
									overlay="shadow-from-b"
									isFullHeight
								>
									<CardHeader
										size="medium"
										className="u-min-height--one-third"
									/>
									<CardContent size="medium" />
									<CardContent
										size="medium"
										className="u-min-height--one-third"
									>
										<Title tag="h3" size="large">
											Création de jeux
										</Title>
										<Markdown>
											Sibylline Escapade est aussi spécialisée dans la création de jeux. Les Escapes Game ou jeux sur-mesure sont réalisés et personnalisés à votre demande par nos équipes. Nous créons une histoire autour de votre entreprise, votre établissement ou votre territoire. Nous avons à cœur de réaliser nous même l’ensemble de nos activités, en utilisant au maximum des produits nobles et écologiques.
										</Markdown>
									</CardContent>
									<CardFooter size="medium" className="c-card__meta">
										<Button
											isPrimary
											color="white"
											href="/[taxon]"
											as="/creation-de-jeux"
										>
											Découvrir notre savoir-faire
										</Button>
									</CardFooter>
								</Card>
							</LayoutItem>

							<LayoutItem
								widths={{
									desktop: "1/3",
								}}
							>
								<Card
									image="/images/agence-evenementielle.jpg"
									hasOverlay
									overlay="shadow-from-b"
									isFullHeight
								>
									<CardHeader
										size="medium"
										className="u-min-height--one-third"
									/>
									<CardContent size="medium" />
									<CardContent
										size="medium"
										className="u-min-height--one-third"
									>
										<Title tag="h3" size="large">
											Team-Building
										</Title>
										<Markdown>
											En panne de nouvelles idées pour vos activités de cohésion ou sorties d’entreprise ? Surprenez vos collaborateurs avec nos activités de team-building. Toutes nos animations sont conçues par nos créateurs de jeux et sont adaptables à votre événement. Nous animons ces activités, dans la France entière, pour des groupes de 10 à 400 personnes.
										</Markdown>
									</CardContent>
									<CardFooter size="medium" className="c-card__meta">
										<Button
											isPrimary
											color="white"
											href="/[taxon]"
											as="/team-building"
										>
											Découvrir nos prestations
										</Button>
									</CardFooter>
								</Card>
							</LayoutItem>
						</Layout>
					</div>
					{/* .u-width--full-absolute */}
				</Wrapper>
				<Navbar
					className={classNames("u-position-absolute", "u-bottom")}
					zIndex={1}
				>
					<Menu align="center" valign="center" hasLayout>
						<Icon
							name="chevron-down"
							size="large"
							color="light-gray"
							onClick={(e) => scrollToNext(e.target)}
						/>
					</Menu>
				</Navbar>
			</Section>
			
			{ ! isNull( news ) && ! isEmpty( news ) && (
				<SectionLayout id="nouveautes">
					<SidebarNav image="/images/v-guiroue.jpg" title="Nouveautés" />
					<SectionContent>
						<Grid items={ news } />
					</SectionContent>
				</SectionLayout>
			) }

			{ ! isNull( testimonials ) && ! isEmpty( testimonials ) && (
				<SectionLayout id="temoignages">
					<SidebarNav image="/images/v-guiroue.jpg" title="Témoignages" />
					<SectionContent
						isFullHeight
						vAlign="center"
					>
						<Carousel
							isAutoplay
						>
							<Grid items={ testimonials } card={ TestimonialCard } />
						</Carousel>
					</SectionContent>
				</SectionLayout>
			) }

			<SectionLayout id="partenaires">
				<SidebarNav
					image="/images/v-drapeau.jpg"
					title="Ils nous font confiance"
				/>
				<SectionContent
					isFullHeight
					vAlign="center"
				>
					<Partners
						title="Nos clients"
						type="clients"
						className="u-margin-top@tablet"
					/>

					<Partners
						title="Nos partenaires"
						type="partenaires"
						className="u-margin-top"
					/>
				</SectionContent>
			</SectionLayout>
		</>
	)
}

function Partners( props ) {
	const {
		type,
		title,
		className
	} = props

	const [items, setItems] = useState( [] )

	useEffect(
		() => {
			if( ! isEmpty( process.env.PARTNERS[type] ) ) {				
				setItems( 
					arrayShuffle(
						process.env.PARTNERS[type]
							.filter(icon => !icon.startsWith("."))
							.map(icon => ({
								name: icon.split('.').slice(0, -1).join('.'),
								image: `/logos/${type}/${icon}`
							})) 
					) 
				)
			}
		},
		[]
	)

	if( isEmpty( items ) )
		return null

	return (
		<Carousel
			title={ title }
			widths={ {
				[null]: '1/3',
				mobile: '1/4',
				tablet: '1/4',
				desktop: '1/5',
				wide: '1/6'
			} }
			size="normal"
			vAlign="middle"
			className={ className }
			isAutoplay
		>
				{ items.map( (item, index) => (
					<div
						key={ `partners-${ type }-item-${ index }` }
						className="o-links-list__link"
					>
						{ item.href
							? (
								<a
									href={ item.href }
									target="_blank"
								>
									<img
										className="o-links-list__image"
										src={ item.image }
										alt={ item.name }
										loading="lazy"
									/>
								</a>
							)
							: (
								<span>
									<img
										className="o-links-list__image"
										src={ item.image }
										alt={ item.name }
										loading="lazy"
									/>
								</span>
							)
						}
					</div>
				) ) }
		</Carousel>
	)
}
