
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import { text } from 'libs/text-manipulation'
import { Figure } from 'components/media'

export default function SidebarNav( props ) {

	const {
		title,
		image,
		children,
		className
	} = props

	return (
		<header
			className="c-sidebar-nav o-layout__item u-1/3@tablet u-1/1@tablet-cart u-position-sticky@tablet u-top@tablet u-position-static@tablet-cart"
		>
			<div
				className={ classNames(
					"c-sidebar-nav__container o-wrapper u-height--full@tablet u-position-relative",
					{
						'u-color--white': image,
						'u-flex-justify--center': ! children
					},
					className
				) }
			>
				{ image && (
					<Figure
						isRounded
						hasOverlay
						overlay="primary"
						image={ image }
					/>
				) }
				{ title && (
					<SidebarNavHeader
						title={ title }
					/>
				) }
				{ children }
			</div>
		</header>
	)
}

export function SidebarNavHeader( props ) {

	const {
		title,
		children
	} = props

	return (
		<div className="c-sidebar-nav__header u-position-relative">
			{ title && (
				<h2 className="o-title o-title--large">
					{ text( title ) }
				</h2>
			) }
			{ children }
		</div>
	)
}

export function SidebarNavContent( props ) {

	const {
		children
	} = props

	return (
		<div className="c-sidebar-nav__content u-position-relative">
			{ children }
		</div>
	)
}

export function SidebarNavFooter( props ) {

	const {
		children
	} = props

	return (
		<div className="c-sidebar-nav__footer u-position-relative">
			{ children }
		</div>
	)
}
